.pageMobile{
    width: 33%;
}

.bgRosenLogo{
    background-color: #FF7588;
}

@media (min-width: 576px) and (max-width: 1280px)  {

    .pageMobile{
        width: 80%
    }    
}

@media (max-width: 576px)  {

    .pageMobile{
        width: 100%
    }    
}

