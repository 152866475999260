
.imgFondoCut {
  background-image: url('../../assets/headerCut.png');
  background-size: cover;
  background-repeat: repeat;
}

.divContent{
  height: 340px;
}

.divForm{
  width: 40%;
}

.divCarusel{

  width: 60%;

}

.divInterno{
  display: flex;
  flex-direction: column;
  height: fit-content;
}

@media (max-width: 600px) {

  .divInterno{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .divForm{
    width: 100%;
    margin-bottom: 30px;
  }

  .divCarusel{

    width: 100%;
  
  }
  

}