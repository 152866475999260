.moveIn {
    animation-duration: 1s;
    animation-name: slideOut;
    animation-iteration-count: 1;
}

.imgBarFondo {
  background-image: url('../../assets/header2.png');
  background-size: auto;
}

.imgBarFooterFondo {
  background-image: url('../../assets/fondofooter.png');
  background-size: auto;
  height: 160px;
}

.imgFondoCut {
  background-image: url('../../assets/headerCut.png');
  background-size: contain;
  background-repeat: repeat;
}

.imgLogo{
  width:40%;
  height:127px;
}

.barra{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48px;
}

.btnHmbgr{
  display: none;
}

.menuIcon{
    display: none; 
}

.menuIcoff{
  display: block; 
  color: black;
  background-color: aquamarine;  
}

.bdyMenu{
  display: block;
}

.bdyMenuOff{
  display: none;
}

@media (max-width: 600px) {

  .btnHmbgr{
    display: block;
  }

  .bdyMenu{
    display: none;
  }

  .btnIcons{
    display:inline-block;
  }
}

@media (max-width: 320px) {
  .menuIcon{
    display: none;
  }

  .btnHmbgr{
    display: block;
  }
}