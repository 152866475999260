.moveIn {
    animation-duration: 1s;
    animation-name: slideOut;
    animation-iteration-count: 1;
}

.imgBarFondo {
  background-image: url('../../assets/datoscontactofooter.png');
  background-repeat:no-repeat;
  background-size: contain;
  background-position:center center;
}

.barra{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48px;
}

.btnHmbgr{
  display: none;
}

.menuIcon{
    display: none; 
}

.menuIcoff{
  display: block; 
  width: fit-content;
  color: black;
  background-color: aquamarine;  
}

.bdyMenu{
  display: block;
}

.bdyMenuOff{
  display: none;
}

@media (max-width: 600px) {

  .btnHmbgr{
    display: block;
  }

  .bdyMenu{
    display: none;
  }

  .btnIcons{
    display:inline-block;
  }
}

@media (max-width: 320px) {
  .menuIcon{
    display: none;
  }

  .btnHmbgr{
    display: block;
  }
}